import { IntrospectionFragmentMatcher } from "apollo-cache-inmemory";

import { fetchGQL } from "../api/fetch";
import { Maybe } from "@technis-counting/shared";

let fetchedFragmentMatcher: Maybe<IntrospectionFragmentMatcher> = undefined;

export const fragmentMatcher = () => {
  if (!fetchedFragmentMatcher) {
    throw Error("Fragment not initialized");
  }

  return fetchedFragmentMatcher;
};

/** Result of the fragmentTypesQuery. */
export const fetchFragmentMatcher = async (force = false) => {
  if (!force && fetchedFragmentMatcher) {
    return fetchedFragmentMatcher;
  }

  const payload = {
    variables: {},
    query: `
		{
			__schema {
				types {
					kind
					name
					possibleTypes {
						name
					}
				}
			}
		}
	`,
  };

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const data = await fetchGQL(payload.query, undefined).then((res: any) => {
    // remove data unrelated to union or interface
    res.__schema.types = res.__schema.types.filter((type: any) => type.possibleTypes !== null);
    return res;
  });

  fetchedFragmentMatcher = new IntrospectionFragmentMatcher({ introspectionQueryResultData: data });

  return fetchedFragmentMatcher;
};
